/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable implicit-arrow-linebreak */
import axios from "axios";
import jwt_decode from "jwt-decode";
import firebase from "../context/firebase.config"

let isRefreshing = false;
let failedQueue = [];
let refreshCounter = 0;
const baseApiV2Url = process.env.REACT_APP_BASE_URL_V2;
const baseApiV3Url = process.env.REACT_APP_BASE_URL_V3;
const basePoplBeUrl = process.env.REACT_APP_BASE_URL_POPLBE;

const defaultAuth = firebase.auth();


const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

export const axiosInstance = axios.create({
  baseURL: baseApiV2Url,
  headers: {
    "Content-Type": "application/json",
  },
});

export const axiosInstanceV3 = axios.create({
  baseURL: baseApiV3Url,
  headers: {
    "Content-Type": "application/json",
  },
});

export const axiosInstancePoplBe = axios.create({
  baseURL: basePoplBeUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

async function reqInterceptors(config) {
  const token = localStorage.getItem("@qrToken");
  config.headers.Authorization = token;
  return config;
}

function resInterceptors(error, instance) {
  const originalRequest = error.config;
  if (error?.response?.status === 401) {
    if (refreshCounter > 30) {
      console.log("LOGOUT");
      processQueue(true, null); // returning error in unseccessfully requests queue
      localStorage.removeItem("@qrToken");
      localStorage.removeItem("@qrRefreshToken");
      // return logout();
      throw error;
    }
    if (isRefreshing) {
      return new Promise((resolve, reject) => { // filling queue with unseccessfully requests
        failedQueue.push({ resolve, reject });
      }).then((token) => {
        originalRequest.headers.Authorization = token;
        return instance(originalRequest);
      }).catch((err) => Promise.reject(err));
    }
    isRefreshing = true;
    return new Promise((resolve, reject) => {
      defaultAuth.onAuthStateChanged(async (user) => {
        if (user) {
          refreshCounter += 1;
          defaultAuth.currentUser.getIdToken(true).then((token) => {
            localStorage.setItem("@qrToken", token);
            /// TEMPORARY CODE FOR TRANSITION FROM OLD SIGN METHOD TO SDK
            const decodedToken = jwt_decode(token);
            if (decodedToken?.firebase?.sign_in_provider === "anonymous") {
              console.log("LOGOUT");
              processQueue(true, null); // returning error in unseccessfully requests queue
              localStorage.removeItem("@qrToken");
              localStorage.removeItem("@qrRefreshToken");
              // return logout();
              return;
            }
            ///
            instance.defaults.headers.common.Authorization = token;
            processQueue(null, token);
            resolve(instance(originalRequest));
          }).catch((error) => {
            processQueue(error, null); // returning error in unseccessfully requests queue
            localStorage.removeItem("@qrToken");
            localStorage.removeItem("@qrRefreshToken");
            // logout();
            reject(error);
          });
        } else {
          localStorage.removeItem("@qrToken");
          localStorage.removeItem("@qrRefreshToken");
          // logout();
        }
      });
      isRefreshing = false;
    });
  }
  if (error?.response?.status > 500) {
    alert("Server Error. Please reload the dashboard and try again. If problem persists, please contact support@popl.co");
  }

  if (refreshCounter) {
    refreshCounter = 0;
  }

  throw error;
}

function successResInterceptors(response) {
  if (refreshCounter) {
    refreshCounter = 0;
  }
  return response;
}

// MANAGING REFRESHING TOKENS
// request interceptors
axiosInstance.interceptors.request.use(reqInterceptors, (error) => Promise.reject(error));
axiosInstanceV3.interceptors.request.use(reqInterceptors, (error) => Promise.reject(error));
axiosInstancePoplBe.interceptors.request.use(reqInterceptors, (error) => Promise.reject(error));

// response interceptor
axiosInstance.interceptors.response.use(successResInterceptors, (error) => resInterceptors(error, axiosInstance));
axiosInstanceV3.interceptors.response.use(successResInterceptors, (error) => resInterceptors(error, axiosInstanceV3));
axiosInstancePoplBe.interceptors.response.use(successResInterceptors, (error) => resInterceptors(error, axiosInstancePoplBe));

const setAxios = () => {
  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
  axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
};

export default setAxios;
